<template>
    <div>
        <v-card-title class="display-1 mb-2">
            {{t('$vuetify.serviceCenter.requestCharger')}}
            <v-spacer/>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    large
                    >
                        mdi-progress-question
                    </v-icon>
                </template>
                <span>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.<br>
                    Magnam, neque totam quasi ipsum necessitatibus amet <br>
                    excepturi ex corporis, aut impedit in ratione animi<br>
                    fugit dolorem voluptatum vel recusandae eaque molestias!
                </span>
            </v-tooltip>
        </v-card-title>
        <v-card-subtitle>{{t('$vuetify.serviceCenter.questionChargerType')}}</v-card-subtitle>
        <v-card-text>
            <v-radio-group v-model="radioAccountType">
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-card flat outlined min-height="250px">
                            <v-card-text class="text-center">
                                <v-radio :label="t('$vuetify.serviceCenter.atCompany')"></v-radio>
                            </v-card-text>
                            <v-card-text align="center">
                                <v-img
                                    style="height: 85px; width: 220px;"
                                    lazy-src="/requests/enterprise.svg"
                                    src="/requests/enterprise.svg"
                                    class="mb-2"
                                ></v-img>
                            </v-card-text>
                            <v-fade-transition>
                                <v-overlay
                                v-if="userDT.data.Relation.IsCompany === 0"
                                absolute
                                color="white"
                                opacity="0.7"
                                >
                                </v-overlay>
                            </v-fade-transition>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-card flat outlined min-height="250px">
                            <v-card-text>
                                <v-radio
                                    :label="t('$vuetify.serviceCenter.atHome')"
                                ></v-radio>
                            </v-card-text>
                            <v-card-text align="center">
                                <v-img
                                    style="height: 85px; width: 150px;"
                                    lazy-src="/requests/homes.svg"
                                    src="/requests/homes.svg"
                                    class="mb-2"
                                ></v-img>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-end">
            <v-btn
                color="primary"
                text
                @click="previousStep"
            >
                <v-icon>mdi-chevron-left</v-icon>
                {{t('$vuetify.generic.back')}}
            </v-btn>
            <v-btn
                color="success"
                text
                @click="nextStep"
            >
                {{t('$vuetify.generic.next')}}
            </v-btn>
            <v-btn
                color="red"
                text
                @click="cancel"
            >
                {{t('$vuetify.generic.cancel')}}
            </v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      radioAccountType: 1
    }
  },
  methods: {
    nextStep () {
      if (this.radioAccountType !== 1) {
        this.$store.commit('requestsState/setStep', 'ContactBack')
      } else {
        this.$store.commit('requestsState/setStep', 'ChargerType')
      }
    },

    previousStep () {
      this.$emit('back')
    },

    cancel () {
      this.$emit('cancel')
    }
  },
  computed: {
    ...mapGetters({ currentStep: 'requestsState/stepName', userDT: 'user/userInfo' })
  }
}
</script>
